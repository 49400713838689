import { createContext, useContext } from "react";
import { Config } from "./types";
import { config } from "./index";

const ConfigContext = createContext<Config | null>(null);

export function ConfigProvider({ children }: { children: React.ReactNode }) {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}

// Hooks
export function useConfig() {
  const context = useContext(ConfigContext);
  if (!context) throw new Error("useConfig must be used within ConfigProvider");
  return context;
}

export function useSiteUrl() {
  const config = useConfig();
  return config.siteUrl;
}

export function isProduction() {
  const config = useConfig();
  return config.environment === "production";
}
