import { useQuery, useMutation } from "urql";
import { GET_NOTIFICATIONS } from "@/urql/queries/get_notifications";
import {
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATIONS,
} from "@/urql/mutations/update_notifications";
import { useState } from "react";

interface NotificationPayload {
  type: "error" | "info" | "warning" | "success";
  title: string;
  description: string;
}

export interface Notification {
  id: string;
  type: "error" | "info" | "warning" | "success";
  title: string;
  description: string;
  created_at: string;
  unread: boolean;
}

export interface FilterOptions {
  type?: "error" | "info" | "warning" | "success";
  startDate?: string;
  endDate?: string;
  unreadOnly?: boolean;
}

export interface PaginationOptions {
  limit: number;
  offset: number;
}

export interface NotificationResponse {
  notifications: Notification[];
  totalCount: number;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

export function useNotifications(userId: string, initialLimit: number = 10) {
  const [pagination, setPagination] = useState<PaginationOptions>({
    limit: initialLimit,
    offset: 0,
  });

  // Main query with pagination
  const [{ data, fetching, error }, refetch] = useQuery({
    query: GET_NOTIFICATIONS,
    variables: {
      userId,
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  // Mutations
  const [, updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const [, deleteNotification] = useMutation(DELETE_NOTIFICATIONS);

  // Action handlers
  const markAsRead = async (notificationIds: string[]) => {
    for (const id of notificationIds) {
      await updateNotification({
        id,
        notificationData: {
          unread: false,
        },
      });
    }
    refetch();
  };

  const markAsUnread = async (notificationIds: string[]) => {
    for (const id of notificationIds) {
      await updateNotification({
        id,
        notificationData: {
          unread: true,
        },
      });
    }
    refetch();
  };

  const deleteNotifications = async (notificationIds: string[]) => {
    for (const id of notificationIds) {
      await deleteNotification({
        id,
      });
    }
    refetch();
  };

  // Pagination handlers
  const nextPage = () => {
    if (data?.notificationsCollection?.pageInfo?.hasNextPage) {
      setPagination((prev) => ({
        ...prev,
        offset: prev.offset + prev.limit,
      }));
    }
  };

  const previousPage = () => {
    if (pagination.offset > 0) {
      setPagination((prev) => ({
        ...prev,
        offset: Math.max(0, prev.offset - prev.limit),
      }));
    }
  };

  const setPage = (pageNumber: number) => {
    setPagination((prev) => ({
      ...prev,
      offset: (pageNumber - 1) * prev.limit,
    }));
  };

  const updatePagination = (newPagination: PaginationOptions) => {
    setPagination(newPagination);
  };

  // Transform response data
  const notifications =
    data?.notificationsCollection?.edges?.map(({ node }: any) => {
      const payload: NotificationPayload = JSON.parse(node.payload);
      return {
        id: node.id,
        type: payload.type,
        title: payload.title,
        description: payload.description,
        created_at: node.created_at,
        unread: node.unread,
      };
    }) || [];

  const totalCount = data?.notificationsCollection?.totalCount || 0;
  const pageInfo = data?.notificationsCollection?.pageInfo || {
    hasNextPage: false,
    hasPreviousPage: pagination.offset > 0,
  };

  return {
    notifications,
    loading: fetching,
    error,
    pagination: {
      ...pagination,
      totalCount,
      currentPage: Math.floor(pagination.offset / pagination.limit) + 1,
      totalPages: Math.ceil(totalCount / pagination.limit),
      hasNextPage: pageInfo.hasNextPage,
      hasPreviousPage: pageInfo.hasPreviousPage,
    },
    actions: {
      markAsRead,
      markAsUnread,
      deleteNotifications,
      nextPage,
      previousPage,
      setPage,
      updatePagination,
    },
  };
}
