import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Octokit } from "octokit";

// interface Repository {
//   id: number;
//   name: string;
//   url: string;
// }

interface Organization {
  id: number;
  text: string;
}

interface OrgManagerState {
  currentOrganizationId: string | null;
  organizations: Organization[];
  loading: boolean;
  error: string | null;
}

const initialState: OrgManagerState = {
  currentOrganizationId: null,
  organizations: [],
  loading: false,
  error: null,
};

// Async thunk for fetching organizations
export const fetchOrganizations = createAsyncThunk(
  "organization/fetchOrganizations",
  async (token: string, { rejectWithValue }) => {
    try {
      const octokit = new Octokit({ auth: token });
      const { data } =
        await octokit.rest.apps.listInstallationsForAuthenticatedUser();

      return data.installations.map((org) => ({
        id: org.id,
        text: org.account.login,
      }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for fetching repositories
// export const fetchRepositories = createAsyncThunk(
//   "organization/fetchRepositories",
//   async (
//     { organizationId, token }: { organizationId: string; token: string },
//     { rejectWithValue },
//   ) => {
//     try {
//       const octokit = new Octokit({ auth: token });

//       const response = await octokit.rest.repos.listForOrg({
//         org: organizationId,
//         type: "all",
//         sort: "updated",
//         direction: "desc",
//         per_page: 100,
//         page: 1,
//       });

//       return response.data.map((repo) => ({
//         id: repo.id,
//         name: repo.name,
//         url: repo.html_url,
//       }));
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   },
// );

const orgSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setCurrentOrganization: (state, action: PayloadAction<string>) => {
      state.currentOrganizationId = action.payload;
    },
    clearOrganization: (state) => {
      state.currentOrganizationId = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload;
        state.loading = false;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
    // .addCase(fetchRepositories.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(fetchRepositories.fulfilled, (state, action) => {
    //   state.repositories = action.payload;
    //   state.loading = false;
    // })
    // .addCase(fetchRepositories.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload as string;
    // });
  },
});

export const { setCurrentOrganization, clearOrganization } = orgSlice.actions;

// Selectors
export const selectOrganizations = (state: { org: OrgManagerState }) =>
  state.org.organizations;
export const selectCurrentOrganizationId = (state: { org: OrgManagerState }) =>
  state.org.currentOrganizationId;
export const selectOrganizationLoading = (state: { org: OrgManagerState }) =>
  state.org.loading;
export const selectOrganizationError = (state: { org: OrgManagerState }) =>
  state.org.error;

export default orgSlice;
