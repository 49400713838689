import { Client, cacheExchange, fetchExchange } from "urql";
import RootState from "@/redux/Store";
import { getConfig } from "@/config";

export const getClient = () => {
  //@ts-ignore
  if (window && window.graphqlClient) {
    //@ts-ignore
    return window.graphqlClient;
  }

  const endpoint = getConfig("gateway").baseUrl; //"https://gateway.staging.codeinput.com";

  let client = new Client({
    url: endpoint,
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: () => {
      const token = getToken();

      return {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  });

  //@ts-ignore
  window.graphqlClient = client;

  return client;
};

const getToken = () => {
  const storage = window.localStorage;
  const token = storage.getItem("jwt_token");

  if (!token) {
    return false;
  }

  return token;
};
