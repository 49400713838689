import { configureStore, combineReducers } from "@reduxjs/toolkit";

import reducers from "@/redux/slices";

import { usermenuListenerMiddleware } from "@/apx/UserMenu/user.slice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth"],
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          //"uploadWasm/addUpload", example action to ignore
        ],
        ignoredPaths: ["auth.isAuthenticated"],
      },
    }).prepend(usermenuListenerMiddleware.middleware),
  //.prepend(authListenerMiddleware.middleware),
  devTools: true,
});

// Export the store
export default store;
// Export the store types
export type RootState = ReturnType<typeof store.getState>;
// Export the persisted store
export const persistor = persistStore(store);
