"use client";

import {
  Header as CarbonHeader,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderMenu,
  Link,
} from "@carbon/react";
import {
  LogoGithub,
  IbmTurbonomic as IbmTurbonomicIcon,
  WorkflowAutomation as WorkflowAutomationIcon,
  Merge as MergeIcon,
  PullRequest as PullRequestIcon,
  Rule as RuleIcon,
  Policy as PolicyIcon,
  Enterprise as EnterpriseIcon,
} from "@carbon/icons-react";
import { ThemeSwitcher } from "@/apx/Theme";
import NotificationPanel from "@/apx/Notification";
import { useAuth } from "@/apx/AuthManager";
import React, { forwardRef } from "react";
import { UserButton, UserPanel } from "@/apx/UserMenu";
import NextLink from "next/link";
import { getConfig } from "@/config";
import { AuthRequired } from "../AuthRequired";
import { CustomHeaderGlobalAction } from "./CustomHeaderGlobalAction";

// specifically made for HeaderName
const CustomLink = forwardRef<HTMLAnchorElement, any>((props, ref) => (
  <NextLink {...props} ref={ref} />
));

export default function Header() {
  return (
    <CarbonHeader aria-label="CodeInput">
      <HeaderName element={CustomLink} href="/" prefix="code">
        input
      </HeaderName>
      <HeaderNavigation aria-label="code [input]">
        <HeaderMenu aria-label="Products List" menuLinkName="Products">
          <NextLink href="/products/merge-conflicts" legacyBehavior passHref>
            <HeaderMenuItem href="/products/merge-conflicts">
              <span style={{ display: "flex", alignItems: "center" }}>
                <PullRequestIcon size={20} style={{ marginRight: "0.5rem" }} />
                Merge Conflicts
              </span>
            </HeaderMenuItem>
          </NextLink>
          <NextLink href="/products/merge-queue" legacyBehavior passHref>
            <HeaderMenuItem href="/products/merge-queue">
              <span style={{ display: "flex", alignItems: "center" }}>
                <MergeIcon size={20} style={{ marginRight: "0.5rem" }} />
                Merge Queue
              </span>
            </HeaderMenuItem>
          </NextLink>
          <NextLink
            href="/products/workflow-automation"
            legacyBehavior
            passHref
          >
            <HeaderMenuItem href="/products/workflow-automation">
              <span style={{ display: "flex", alignItems: "center" }}>
                <WorkflowAutomationIcon
                  size={20}
                  style={{ marginRight: "0.5rem" }}
                />
                Workflow Automation
              </span>
            </HeaderMenuItem>
          </NextLink>
          <NextLink href="/products/insights" legacyBehavior passHref>
            <HeaderMenuItem href="/products/insights">
              <span style={{ display: "flex", alignItems: "center" }}>
                <IbmTurbonomicIcon
                  size={20}
                  style={{ marginRight: "0.5rem" }}
                />
                Insights
              </span>
            </HeaderMenuItem>
          </NextLink>
        </HeaderMenu>
        <NextLink href="/pricing" legacyBehavior passHref>
          <HeaderMenuItem>Pricing</HeaderMenuItem>
        </NextLink>
        <NextLink href="/blog" legacyBehavior passHref>
          <HeaderMenuItem>Blog</HeaderMenuItem>
        </NextLink>
        <HeaderMenu aria-label="Company Menu" menuLinkName="Company">
          <NextLink href="/about" legacyBehavior passHref>
            <HeaderMenuItem href="/about">
              <span style={{ display: "flex", alignItems: "center" }}>
                <EnterpriseIcon size={20} style={{ marginRight: "0.5rem" }} />
                About Us
              </span>
            </HeaderMenuItem>
          </NextLink>
          <NextLink href="/terms" legacyBehavior passHref>
            <HeaderMenuItem href="/terms">
              <span style={{ display: "flex", alignItems: "center" }}>
                <RuleIcon size={20} style={{ marginRight: "0.5rem" }} />
                Terms of Service
              </span>
            </HeaderMenuItem>
          </NextLink>
          <NextLink href="/privacy" legacyBehavior passHref>
            <HeaderMenuItem href="/privacy">
              <span style={{ display: "flex", alignItems: "center" }}>
                <PolicyIcon size={20} style={{ marginRight: "0.5rem" }} />
                Privacy Policy
              </span>
            </HeaderMenuItem>
          </NextLink>
        </HeaderMenu>
      </HeaderNavigation>
      <HeaderGlobalBar>
        <AuthRequired returnEmpty>
          <NotificationPanel />
        </AuthRequired>
        <ThemeSwitcher />
        <LoginButton2 />
      </HeaderGlobalBar>
      <UserPanel />
    </CarbonHeader>
  );
}

function LoginButton() {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <HeaderGlobalAction aria-label="Login with Github" tooltipAlignment="end">
        <Link href={`${getConfig("api").baseUrl}/login`}>
          <LogoGithub size={24} />
        </Link>
      </HeaderGlobalAction>
    );
  }

  return <UserButton />;
}

function LoginButton2() {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return (
      <Link href={`${getConfig("api").baseUrl}/login`}>
        <CustomHeaderGlobalAction
          aria-label="Login with Github"
          tooltipAlignment="end"
          icon={<LogoGithub size={24} />}
          text="Login with Github"
        />
      </Link>
    );
  }
  return <UserButton />;
}
