"use client";

import { PostHogProvider } from "posthog-js/react";
import { useConfig } from "@/config/ConfigContext";
import { useAuth } from "@/apx/AuthManager";
import { useEffect } from "react";
import posthog from "posthog-js";
import PostHogPageView from "./PostHogPageView";

/**
 * Provides PostHog analytics integration with conditional initialization based on config.
 * - Only initializes PostHog when enabled in configuration
 * - Ensures client-side only initialization
 * - Falls back to simple fragment when disabled
 */
export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  const { posthog: posthogConfig } = useConfig();

  const { isAuthenticated, primaryAccount, decodedToken } = useAuth();

  if (posthogConfig.enabled && typeof window !== "undefined") {
    const options = {
      api_host: posthogConfig.proxyHost,
      ui_host: "https://us.posthog.com",
      autocapture: true,
      capture_pageview: false,
      capture_pageleave: true,
      disable_session_recording: false,
      disable_persistence: false,
      disable_surveys: false,
      disable_web_experiments: true,
      disable_external_dependency_loading: false,
      persistance: "localStorage+cookie",
      cookie_expiration: 365,
      loaded: (posthog) => {},
      save_referrer: true,
      save_campaign_params: true,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      debug: false,
      person_profiles: "always",
    };

    useEffect(() => {
      if (isAuthenticated) {
        posthog.identify(decodedToken.sub, {
          email: primaryAccount.email,
          name: primaryAccount.username,
        });
      }
    }, [isAuthenticated]);

    return (
      <PostHogProvider apiKey={posthogConfig.apiKey} options={options}>
        <PostHogPageView />
        {children}
      </PostHogProvider>
    );
  }

  return <>{children}</>;
}
