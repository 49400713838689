"use client";

import { HeaderGlobalAction, Theme } from "@carbon/react";
import {
  Notification as NotificationIcon,
  NotificationNew as NotificationNewIcon,
} from "@carbon/icons-react";
import { NotificationsPanel } from "@carbon/ibm-products";
import { useState } from "react";
import { useNotifications } from "@/apx/Notification/useNotifications";
import { useRouter } from "next/navigation";
import { useAuth } from "@/apx/AuthManager";
import { useTheme } from "@/apx/Theme";

export default function Notification() {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { isDark } = useTheme();

  const { isAuthenticated, decodedToken } = useAuth();

  const userId = decodedToken?.sub;

  const { notifications, loading, actions } = useNotifications(userId);

  // Transform notifications to match NotificationsPanel format
  const panelData = notifications
    .filter((n) => n.unread) // Only show unread notifications
    .map((notification) => ({
      id: notification.id,
      type: notification.type === "info" ? "informational" : notification.type, // Convert 'info' to 'informational'
      title: notification.title,
      description: notification.description,
      timestamp: new Date(notification.created_at),
      unread: notification.unread,
      onNotificationClick: () => {
        router.push("/notifications");
        setOpen(false);
      },
    }));

  const hasUnreadNotifications = notifications.some((n) => n.unread);

  const handleDismissAll = () => {
    const unreadIds = notifications.filter((n) => n.unread).map((n) => n.id);
    actions.markAsRead(unreadIds);
  };

  const handleDismissSingle = (notification) => {
    actions.markAsRead([notification.id]);
  };

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <>
      <HeaderGlobalAction
        aria-label="Notifications"
        onClick={() => {
          setOpen(!open);
        }}
        isActive={open}
        disabled={loading}
      >
        {hasUnreadNotifications ? (
          <NotificationNewIcon size={22} />
        ) : (
          <NotificationIcon size={22} />
        )}
      </HeaderGlobalAction>
      <Theme theme="g10">
        <NotificationsPanel
          data={panelData}
          onClickOutside={() => setOpen(false)}
          onDismissAllNotifications={handleDismissAll}
          onDismissSingleNotification={handleDismissSingle}
          onDoNotDisturbChange={function noRefCheck() {}}
          open={open}
          className={isDark ? "cds--g100" : "cds--g10"}
        />
      </Theme>
    </>
  );
}
