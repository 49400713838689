import { themeSlice } from "@/apx/Theme";
import { alertSlice } from "@/apx/Alert";
import { authSlice } from "@/apx/AuthManager";
import { usermenuSlice } from "@/apx/UserMenu";
import { orgSlice } from "@/apx/OrgManager";
import { userSlice } from "@/hooks/user";

export default {
  theme: themeSlice.reducer,
  alert: alertSlice.reducer,
  auth: authSlice.reducer,
  usermenu: usermenuSlice.reducer,
  user: userSlice.reducer,
  org: orgSlice.reducer,
};
