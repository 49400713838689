"use client";

import { DotMark as DotMarkIcon } from "@carbon/icons-react";
import React, { useState } from "react";

import {
  networkStatus,
  networkStatusLoading,
  networkStatusError,
  statusList,
  statusListItem,
  statusDot,
  statusText,
} from "./NetworkStatus.module.scss";
import { Modal } from "@carbon/react";

import { useQuery } from "urql";

import { STATUS_QUERY } from "@/urql/queries/status";
import { SystemStatus } from "@/urql/gql/graphql";

interface ServiceStatus {
  name: string;
  status: SystemStatus;
}

const services: string[] = ["Front-end", "API", "GraphQL", "Webhook"];

const statusColors = {
  [SystemStatus.Online]: "#00b894",
  [SystemStatus.Degraded]: "#f1c40f",
  [SystemStatus.Offline]: "#e74c3c",
};

export default function NetworkStatus() {
  const [open, setOpen] = useState(false);

  const [result] = useQuery({
    query: STATUS_QUERY,
    requestPolicy: "network-only",
  });

  const overallStatus = result.error
    ? SystemStatus.Offline
    : result.data?.status || SystemStatus.Offline;

  // Mock data for each service's status (replace with actual data later)
  const serviceStatuses: ServiceStatus[] = services.map((service) => ({
    name: service,
    status: overallStatus, // Temporary; replace with actual individual statuses when available
  }));

  const getStatusClass = () => {
    if (result.error) return networkStatusError;
    if (overallStatus === SystemStatus.Degraded) return networkStatusLoading;
    if (overallStatus === SystemStatus.Online) return networkStatus;
    return networkStatusError;
  };

  const modalHeading = "System Status";

  return (
    <>
      <div className={getStatusClass()}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          <DotMarkIcon />| {result.fetching ? "Connecting..." : overallStatus}
        </a>
      </div>

      <Modal
        open={open}
        onRequestClose={() => setOpen(false)}
        passiveModal
        modalHeading={modalHeading}
      >
        <div>
          <ul className={statusList}>
            {serviceStatuses.map((service) => (
              <li key={service.name} className={statusListItem}>
                <DotMarkIcon
                  className={statusDot}
                  style={{ fill: statusColors[service.status] }}
                />
                <span className={statusText}>
                  {service.name}: <strong>{service.status}</strong>
                </span>
              </li>
            ))}
          </ul>
          {result.error && (
            <p style={{ color: "#e74c3c", marginTop: "1rem" }}>
              Error details: {result.error.message}
            </p>
          )}
        </div>
      </Modal>
    </>
  );
}
