import { gql } from "urql";

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($userId: UUID!, $limit: Int!, $offset: Int!) {
    notificationsCollection(
      filter: { user_id: { eq: $userId } }
      first: $limit
      offset: $offset
      orderBy: [{ created_at: DescNullsLast }]
    ) {
      edges {
        node {
          id
          user_id
          created_at
          unread
          payload
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;
