"use client";

import { InlineNotification } from "@carbon/react";
import { InlineNotificationProps } from "@carbon/react";
import { useRef } from "react";
import { createRoot } from "react-dom/client";

export { alertSlice } from "./Alert.slice";

export function Alert({
  title,
  subtitle,
  kind,
}: {
  title: string;
  subtitle: string;
  kind: InlineNotificationProps["kind"];
}) {
  const myRef = useRef<HTMLDivElement>(null);

  setTimeout(() => {
    if (myRef.current) {
      myRef.current.style.transform = "translateX(-120%)";
    }
  }, 4000);

  setTimeout(() => {
    if (myRef.current) {
      myRef.current.remove();
    }
  }, 4500);

  return (
    <div ref={myRef} style={{ transition: "transform 0.5s ease-out" }}>
      <InlineNotification
        aria-label="Close"
        statusIconDescription="notification"
        subtitle={subtitle}
        title={title}
        style={{ marginBottom: "1rem" }}
        kind={kind}
      />
    </div>
  );
}

export default function AlertContainer() {
  return (
    <div
      id="alert-container"
      style={{
        position: "absolute",
        zIndex: "100000",
        left: "1rem",
        bottom: "1rem",
      }}
    ></div>
  );
}

export function injectAlert(
  subtitle: string,
  title: string,
  kind: InlineNotificationProps["kind"],
) {
  const container = document.getElementById("alert-container");
  if (container) {
    const notification = document.createElement("div");
    container.appendChild(notification);

    createRoot(notification).render(
      <Alert subtitle={subtitle} title={title} kind={kind} />,
    );
  }
}

// TODO: This leaves a dangling div in the DOM. Fix it.
