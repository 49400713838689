"use client";

import { Provider } from "react-redux";
import store from "@/redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "@/redux/Store";
import { ThemeWrapper } from "@/apx/Theme";
import UrqlProvider from "@/urql/Provider";
import { AuthManager } from "@/apx/AuthManager";
import { CSPostHogProvider } from "@/apx/PostHog";
import { ConfigProvider } from "@/config/ConfigContext";
import CarbonConfig from "@/config/Carbon";

interface ProvidersProps {
  children: React.ReactNode;
}

/**
 * Providers component that wraps the application with various context providers
 *
 * Provider order (from outside to inside):
 * 1. Redux Store Provider
 * 2. Redux Persist Gate
 * 3. Auth Manager
 * 4. URQL (GraphQL Client)
 * 5. Theme Wrapper
 * 6. PostHog Analytics (enabled only in production)
 *
 */
export function Providers({ children }: ProvidersProps) {
  return (
    <ConfigProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthManager>
            <UrqlProvider>
              <CSPostHogProvider>
                <CarbonConfig>
                  <ThemeWrapper>{children}</ThemeWrapper>
                </CarbonConfig>
              </CSPostHogProvider>
            </UrqlProvider>
          </AuthManager>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
}
