"use client";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchOrganizations,
  setCurrentOrganization,
  clearOrganization,
  selectCurrentOrganizationId,
  selectOrganizations,
  selectOrganizationLoading,
  selectOrganizationError,
} from "./org.slice";

// Hook for managing organization selection
export const useOrg = () => {
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const loading = useSelector(selectOrganizationLoading);
  const error = useSelector(selectOrganizationError);

  const selectOrganization = (organizationId: string) => {
    dispatch(setCurrentOrganization(organizationId));
  };

  const resetOrganization = () => {
    dispatch(clearOrganization());
  };

  return {
    currentOrganizationId,
    loading,
    error,
    selectOrganization,
    resetOrganization,
  };
};

// Hook for managing repositories
// export const useRepositories = () => {
//   const dispatch = useDispatch();
//   const repositories = useSelector(selectRepositories);
//   const loading = useSelector(selectOrganizationLoading);
//   const error = useSelector(selectOrganizationError);

//   const loadRepositories = (organizationId: string, token: string) => {
//     dispatch(fetchRepositories({ organizationId, token }));
//   };

//   return {
//     repositories,
//     loading,
//     error,
//     loadRepositories,
//   };
// };

// Hook that combines organization selection and repository loading
// export const useOrganizationRepositories = (organizationId?: string) => {
//   const dispatch = useDispatch();
//   const currentOrganizationId = useSelector(selectCurrentOrganizationId);
//   const repositories = useSelector(selectRepositories);
//   const loading = useSelector(selectOrganizationLoading);
//   const error = useSelector(selectOrganizationError);

//   useEffect(() => {
//     if (organizationId) {
//       dispatch(setCurrentOrganization(organizationId));
//     }
//   }, [organizationId, dispatch]);

//   const loadRepositories = (token: string) => {
//     if (currentOrganizationId) {
//       dispatch(
//         fetchRepositories({ organizationId: currentOrganizationId, token }),
//       );
//     }
//   };

//   return {
//     currentOrganizationId,
//     repositories,
//     loading,
//     error,
//     loadRepositories,
//   };
// };

// Hook for automatic repository loading when organization changes
// export const useAutoLoadRepositories = (token: string) => {
//   const dispatch = useDispatch();
//   const currentOrganizationId = useSelector(selectCurrentOrganizationId);
//   const repositories = useSelector(selectRepositories);
//   const loading = useSelector(selectOrganizationLoading);
//   const error = useSelector(selectOrganizationError);

//   useEffect(() => {
//     if (currentOrganizationId && token) {
//       dispatch(
//         fetchRepositories({ organizationId: currentOrganizationId, token }),
//       );
//     }
//   }, [currentOrganizationId, token, dispatch]);

//   return {
//     currentOrganizationId,
//     repositories,
//     loading,
//     error,
//   };
// };

// re-export all the org slice functions
export {
  fetchOrganizations,
  //fetchRepositories,
  setCurrentOrganization,
  clearOrganization,
  selectOrganizations,
  selectCurrentOrganizationId,
  //selectRepositories,
  selectOrganizationLoading,
  selectOrganizationError,
};
export { default as orgSlice } from "./org.slice";
