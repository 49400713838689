"use client";

import { pkg } from "@carbon/ibm-products";
import { ReactNode } from "react";

interface CarbonConfigProps {
  children: ReactNode;
}

export default function CarbonConfig({ children }: CarbonConfigProps) {
  // Initialize Carbon config
  //pkg.prefix = "cds";
  pkg.component.Toolbar = true;
  pkg.component.ToolbarGroup = true;
  pkg.component.ToolbarButton = true;

  return <>{children}</>;
}

export function initializeCarbonConfig() {
  pkg.component.Toolbar = true;
  pkg.component.ToolbarGroup = true;
  pkg.component.ToolbarButton = true;
}
