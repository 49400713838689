import { gql } from "urql";

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: UUID!
    $notificationData: notificationsUpdateInput!
  ) {
    updatenotificationsCollection(
      filter: { id: { eq: $id } }
      set: $notificationData
    ) {
      affectedCount
      records {
        id
        user_id
        created_at
        unread
        payload
      }
    }
  }
`;

export const DELETE_NOTIFICATIONS = gql`
  mutation DeleteNotifications($id: UUID!) {
    deleteFromnotificationsCollection(filter: { id: { eq: $id } }) {
      affectedCount
      records {
        id
        user_id
        created_at
        unread
        payload
      }
    }
  }
`;
