"use client";

import { useTheme } from "@/apx/Theme";

const lightPattern =
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' enable-background='new 0 0 16 16' viewBox='0 0 16 16'%3E%3Crect width='16' height='16' fill='none'/%3E%3Crect width='1' height='1' fill='rgba(0,0,0,0.35)'/%3E%3C/svg%3E";
const darkPattern =
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' enable-background='new 0 0 16 16' viewBox='0 0 16 16'%3E%3Crect width='16' height='16' fill='none'/%3E%3Crect width='1' height='1' fill='rgba(255,255,255,0.35)'/%3E%3C/svg%3E";

export default function Background() {
  const { isDark } = useTheme();

  return (
    <div
      style={{
        position: "fixed",
        top: "47px",
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        backgroundImage: `url("${isDark ? darkPattern : lightPattern}")`,
        backgroundColor: isDark ? "#121212" : "#f5f5f5",
        backgroundSize: "16px",
      }}
    />
  );
}
