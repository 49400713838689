"use client";

import {
  SwitcherItem as SwitcherItemCarbon,
  Grid,
  Column,
} from "@carbon/react";
import React from "react";
import { Launch as LaunchIcon } from "@carbon/icons-react";
import Link from "next/link";

export const SwitcherItem = ({
  children,
  outbound,
  href,
  onClick,
  ariaLabel,
  ...props
}: any) => {
  const [icon, text] = React.Children.toArray(children);

  let switcher_item = (
    <SwitcherItemCarbon
      target={outbound ? "_blank" : ""}
      rel="noopener noreferrer"
      handleSwitcherItemFocus={() => {}}
      onClick={onClick}
      {...((ariaLabel && { "aria-label": ariaLabel }) || {
        "aria-label": "Menu item",
      })}
      {...props}
    >
      <Grid fullWidth style={{ paddingLeft: "0", paddingRight: "0" }}>
        <Column sm={2} md={2} lg={3}>
          {icon}
        </Column>
        <Column
          sm={2}
          md={5}
          lg={10}
          style={{
            lineHeight: "1.5rem",
          }}
        >
          <span>{text}</span>
        </Column>
        {outbound && (
          <Column sm={0} md={2} lg={3} style={{ paddingTop: "2px" }}>
            <LaunchIcon size={20} />
          </Column>
        )}
      </Grid>
    </SwitcherItemCarbon>
  );

  if (href != undefined) {
    switcher_item = (
      <Link href={href} passHref legacyBehavior>
        {switcher_item}
      </Link>
    );
  }

  return switcher_item;
};
