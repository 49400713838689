import { Provider } from "urql";
import { getClient } from "./client";

interface UrqlProviderProps {
  children: React.ReactNode;
}

const UrqlProvider = ({ children }: UrqlProviderProps) => {
  const client = getClient();
  return <Provider value={client}>{children}</Provider>;
};

export default UrqlProvider;
