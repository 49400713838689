"use client";

import {
  HeaderGlobalAction,
  HeaderPanel,
  SideNavMenu,
  SideNavItems,
} from "@carbon/react";
import {
  UserAvatar as UserAvatarIcon,
  Logout as LogoutIcon,
  Dashboard as DashboardIcon,
  UserAccess as UserAccessIcon,
  UserSettings as UserSettingsIcon,
  Notification as NotificationIcon,
} from "@carbon/icons-react";
import React, { useEffect } from "react";
import { SwitcherItem } from "@/shared/Carbon";
import { toggleUserTab } from "./user.slice";
import { connect } from "react-redux";
import { useAuth } from "@/apx/AuthManager";

function UserButtonPre({ profileExpanded, toggleProfile }: any) {
  const { isAuthenticated } = useAuth();
  return (
    <HeaderGlobalAction
      aria-label="User Profile"
      aria-expanded={true}
      isActive={profileExpanded}
      onClick={() => {
        toggleProfile();
      }}
      tooltipAlignment="end"
      disabled={!isAuthenticated}
    >
      <UserAvatarIcon size={24} />
    </HeaderGlobalAction>
  );
}

function UserPanelPre({ profileExpanded }: any) {
  const { isAuthenticated, logout, primaryAccount } = useAuth();

  useEffect(() => {}, [isAuthenticated]);

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <HeaderPanel
      expanded={profileExpanded}
      addFocusListeners={false}
      onHeaderPanelFocus={() => {}}
    >
      <SideNavItems>
        <SideNavMenu title="User" defaultExpanded={true}>
          <SwitcherItem aria-label="username" href="#">
            <UserAccessIcon size={22} />
            {primaryAccount?.username}
          </SwitcherItem>
          <SwitcherItem aria-label="Dashboard Button" href="/dashboard">
            <DashboardIcon size={22} />
            Dashboard
          </SwitcherItem>
          <SwitcherItem aria-label="" href="/settings">
            <UserSettingsIcon size={22} />
            Settings
          </SwitcherItem>
          <SwitcherItem aria-label="" href="/notifications">
            <NotificationIcon size={22} />
            Notifications
          </SwitcherItem>
          <SwitcherItem
            aria-label=""
            href="#"
            onClick={(e: any) => {
              e.preventDefault();
              logout();
            }}
          >
            <LogoutIcon size={22} />
            Log out
          </SwitcherItem>
          <div
            style={{
              paddingBottom: "1.5rem",
            }}
          ></div>
        </SideNavMenu>
      </SideNavItems>
    </HeaderPanel>
  );
}

const mapStateToProps = (state: any) => ({
  profileExpanded: state.usermenu.userTab,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleProfile: () => dispatch(toggleUserTab()),
});

export const UserPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPanelPre);

export const UserButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserButtonPre);

export { usermenuSlice } from "./user.slice";

// for the future...
// <SideNavMenu aria-label="" title="Organization" defaultExpanded={true}>
//           <SwitcherItem aria-label="">
//             <DashboardIcon size={22} />
//             Dashboard
//           </SwitcherItem>
//           <SwitcherItem aria-label="">
//             <PullRequestIcon size={22} />
//             Pull Requests
//           </SwitcherItem>
//           <SwitcherItem
//             aria-label=""
//             href=""
//             onClick={(e: any) => {
//               e.preventDefault();
//             }}
//           >
//             <SettingsAdjustIcon size={22} />
//             Settings
//           </SwitcherItem>
//           <div
//             style={{
//               paddingBottom: "1.5rem",
//             }}
//           ></div>
//         </SideNavMenu>
