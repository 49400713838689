import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
} from "@reduxjs/toolkit";

// User Slice
export interface UserSlice {
  userTab: boolean;
}

// Initial State
const initialState: UserSlice = {
  userTab: false,
};

export const openUserTab = createAsyncThunk(
  "user/openUserTab",
  async (_, thunkAPI) => {
    // dispatch global closePanels
    thunkAPI.dispatch({ type: "global/closePanels" });

    // open the user panel
    thunkAPI.dispatch(usermenuSlice.actions.openUserTab());
  },
);

export const closeUserTab = createAsyncThunk(
  "user/closeUserTab",
  async (_, thunkAPI) => {
    // close the notification panel
    thunkAPI.dispatch(usermenuSlice.actions.closeUserTab());
  },
);

export const toggleUserTab = createAsyncThunk(
  "user/toggleUserTab",
  async (_, thunkAPI) => {
    // dispatch global closePanels only if the notification panel is
    // closed
    if (!thunkAPI.getState().usermenu.userTab) {
      thunkAPI.dispatch({ type: "global/closePanels" });
    }

    // toggle the notification panel
    thunkAPI.dispatch(usermenuSlice.actions.toggleUserTab());
  },
);

export const usermenuSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    openUserTab: (state: UserSlice) => {
      state.userTab = true;

      return state;
    },
    closeUserTab: (state: UserSlice) => {
      state.userTab = false;

      return state;
    },
    toggleUserTab: (state: UserSlice) => {
      state.userTab = !state.userTab;

      return state;
    },
  },
});

// Create Listener Middleware
const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  type: "global/closePanels",
  effect: async (action, listenerApi) => {
    // close the notification panel
    listenerApi.dispatch(usermenuSlice.actions.closeUserTab());
  },
});

export const usermenuListenerMiddleware = listenerMiddleware;
