"use client";

import React, { ReactElement } from "react";
import { HeaderGlobalAction } from "@carbon/react";
import styles from "./CustomHeaderGlobalAction.module.scss";

interface CustomHeaderGlobalActionProps {
  /** The icon to display */
  icon: ReactElement;
  /** Text to display next to the icon */
  text?: string;
  /** ARIA label for the action */
  "aria-label": string;
  /** Optional tooltip alignment */
  tooltipAlignment?: "start" | "center" | "end";
  /** Optional click handler */
  onClick?: () => void;
  /** Additional props */
  [key: string]: any;
}

export function CustomHeaderGlobalAction({
  icon,
  text,
  "aria-label": ariaLabel,
  tooltipAlignment,
  onClick,
  ...rest
}: CustomHeaderGlobalActionProps) {
  return (
    <HeaderGlobalAction
      aria-label={ariaLabel}
      tooltipAlignment={tooltipAlignment}
      onClick={onClick}
      {...rest}
      className={styles.customAction}
    >
      {icon}
      {text && <span className={styles.actionText}>{text}</span>}
    </HeaderGlobalAction>
  );
}
